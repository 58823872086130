import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SideMenu, HeaderBar, SecondaryBar, ToastNotification } from '@filament-ai/filament-ui';

import { setSideMenu, setDarkMode, setSearchValue } from '../../actions/preferences';
import { setToastNotifications } from '../../actions/notifications';
import { logout } from '../../utils/auth';
import { ROUTES } from '../../constants';
import history from '../../config/history';

class PageWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    
    returnProfile(currentUser) {
        return {
            ...currentUser,
            name: `${currentUser.first_name} ${currentUser.last_name}`,
            role: currentUser.roles.name
        };
    }

    returnProfileOptions() {
        const { logout } = this.props;

        return [[{
            name: 'Profile',
            icon: 'user',
            action: (route) => history.push(route.route),
            route: ROUTES.PROFILE
        }, {
            icon: 'users',
            name: 'Team',
            action: (route) => history.push(route.route),
            route: ROUTES.TEAM
        }], [{
            icon: 'sign-out-alt',
            name: 'Logout',
            action: logout
        }]];
    }

    returnMenuLinks() {
        return [{
            name: 'Jobs',
            route: ROUTES.JOBS,
            icon: 'clipboard-list'
        },{
            name: 'All Files',
            route: ROUTES.VIEW_FILES,
            icon: 'file'
        },{
            name: 'Train a Model',
            route: ROUTES.TRAIN,
            icon: 'graduation-cap'
        }];
    }

    updateDimensions() {
        const { setSideMenu, sideMenuOpen } = this.props;

        if (window.innerWidth < 1025 && sideMenuOpen) {
            setSideMenu(!sideMenuOpen);
        }

        this.setState({
            width: window.innerWidth
        }, () => {
            this.forceUpdate();
        });
    }

    getWrapperClasses() {
        const { darkMode, sideMenuOpen } = this.props;
        const { width } = this.state;

        return `wrapper content ${darkMode ? 'inverted-colours' : ''} ${sideMenuOpen && width >= 1025 ? 'content-float' : ''} ${!sideMenuOpen && width < 1025 ? 'content-small' : ''}`;
    }

    clearNotification(index) {
        const { toastNotifications, setToastNotifications } = this.props;

        toastNotifications.splice(index, 1);

        setToastNotifications(toastNotifications);
    }

    render() {
        const {
            headerBar, sideMenu, sideMenuOpen, setSideMenu, darkMode, setDarkMode, subHeader, currentUser, children,
            headerTabs, toastNotifications
        } = this.props;
        const { width } = this.state;

        return (
            <div className='page-wrapper'>
                {sideMenu && (
                    <SideMenu
                        toggleAction={() => setSideMenu(!sideMenuOpen)}
                        darkModeAction={() => setDarkMode(!darkMode)}
                        sideMenuState={sideMenuOpen}
                        darkModeState={darkMode}
                        windowWidth={width}
                        menuLinks={this.returnMenuLinks()}
                        listItems={[]}
                        rootLink={ROUTES.LANDING}
                        
                    />
                )}
                <div className={this.getWrapperClasses()}>
                    {headerBar && (
                        <HeaderBar    
                            toggleAction={ this.props.toggleSideMenu }
                            hasSecondary={!!subHeader}
                            windowWidth={width}
                            headerTabs={headerTabs}
                            profile={this.returnProfile(currentUser)}
                            profileOptions={this.returnProfileOptions()}
                            
                        />
                    )}
                    
                    {(subHeader || headerTabs) && width < 1200 && (
                        <SecondaryBar
                            subHeader={headerTabs || subHeader}
                        />
                    )}
                    
                    

                    <div className={`pt2 mb3 ${width < 1025 ? 'px1' : 'px3'}`}>
                        {children}
                    </div>

                    {!!(toastNotifications && toastNotifications.length) && toastNotifications.map((toast, index) => (
                        <ToastNotification
                            key={ index }
                            stacked={ index + 1 }
                            type={ toast.type }
                            message={ toast.message }
                            onClick={ () => this.clearNotification(index) }
                        />
                    ))}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        darkMode: state.preferences.darkMode,
        sideMenuOpen: state.preferences.sideMenuOpen,
        searchValue: state.preferences.searchValue,
        currentUser: state.auth.currentUser,
        authToken: state.auth.authToken,
        toastNotifications: state.notifications.toastNotifications
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setDarkMode,
        setSideMenu,
        setSearchValue,
        logout,
        setToastNotifications
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper);
