import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Input, Button, ToastNotification, Icon } from '@filament-ai/filament-ui';

import { API } from '../../config/api';
import { formatEndpoint, postData } from '../../utils/api';
import { validatePassword, getInvalidPasswordMessage } from '../../utils/validations';
import { addToastNotification, setToastNotifications } from '../../actions/notifications';
import { API_STATUS_CODES, ROUTES } from '../../constants';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordConfirm: '',
            passwordChanged: false,
            showError: false,
            id: '',
            token: ''
        };
    }

    componentDidMount() {
        const { match } = this.props;

        this.setState({
            id: match.params.id,
            token: match.params.token
        });
    }

    async submitForm(e) {
        if (e) {
            e.preventDefault();
        }

        const { id, token, password } = this.state;
        const { addToastNotification } = this.props;

        try {
            const endpoint = formatEndpoint(API.auth.submit_password, { id, token });
            const payload = { password };

            const resetResult = await postData(endpoint, payload);

            if (resetResult.status === API_STATUS_CODES.OK) {
                this.setState({
                    passwordChanged: true
                });
            }
        } catch(err) {
            this.setState({
                passwordChanged: false
            });

            addToastNotification({
                type: 'error',
                message: err.message || 'Your password reset link is invalid. Please request another one and try again.'
            });
        }
    }

    clearNotification(index) {
        const { toastNotifications, setToastNotifications } = this.props;

        toastNotifications.splice(index, 1);

        setToastNotifications(toastNotifications);
    }

    render() {
        const { password, passwordConfirm, showError, passwordChanged } = this.state;
        const { toastNotifications } = this.props;

        return (
            <div className='page-height bg-white flex-centred w-100'>
                { (passwordChanged) ? (
                    <header className='w-55 w-85-tab'>
                        <div className='mt3 flex flex-ac flex-jc flex-col' style={ {height: '100%'} }>
                            <h1 className='centred mb1 massive-icon'>
                                <Icon icon='check-circle' className='success' />
                            </h1>
                            <p className='lead text-secondary centred'>Your password has been successfully changed. You can now go back and log in.</p>
                            <Link to={ROUTES.LOGIN} className='button primary w-30 mt2'>← Back to Login</Link>
                        </div>
                    </header>
                ) : (
                    <header className='w-55 w-85-tab'>
                        <Link to={ROUTES.LOGIN}>← Back to Login</Link>
                        <h4 className='mt3'>Reset Password</h4>
                        <p className='accent-dark small'>
                            Enter and confirm your desired password to update it for your account.
                        </p>

                        <form className='my2' onSubmit={ (e) => this.submitForm(e) }>
                            <Input
                                type='password'
                                placeholder='Enter your password...'
                                inputId='password'
                                label='Password'
                                error={ password.length > 0 && !validatePassword(password) }
                                errorMessage={ (password.length > 0) ? getInvalidPasswordMessage(password) : null }
                                value={ password }
                                onChange={ (e) => this.setState({password: e.target.value}) }
                            />

                            <Input
                                type='password'
                                placeholder='Confirm your password...'
                                inputId='passwordConfirm'
                                label='Password Confirmation'
                                error={ passwordConfirm.length > 0 && passwordConfirm !== password }
                                errorMessage='Please make sure both password fields match.'
                                value={ passwordConfirm }
                                onChange={ (e) => this.setState({passwordConfirm: e.target.value}) }
                            />

                            <Button 
                                className='primary w-100 t-mt2'
                                disabled={ password.length === 0 || !validatePassword(password) || password !== passwordConfirm || showError }
                                type={ 'submit' }
                                name={ 'Reset' }
                                onClick={ (e) => this.submitForm(e) }
                            />
                        </form>
                    </header>
                ) }

                { !!(toastNotifications && toastNotifications.length) && toastNotifications.map((toast, index) => (
                    <ToastNotification
                        key={ index }
                        stacked={ index + 1 }
                        type={ toast.type }
                        message={ toast.message }
                        onClick={ () => this.clearNotification(index) }
                    />
                )) }
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        toastNotifications: state.notifications.toastNotifications
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        addToastNotification,
        setToastNotifications
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);