 import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Table, Label } from '@filament-ai/filament-ui';
import moment from 'moment';

import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Loader from '../../components/Loader/Loader';
import ConfirmationModal from '../../components/Modals/Confirmation';
import InviteNewUserModal from '../../components/Modals/InviteNewUser';
import EditUserRoleModal from '../../components/Modals/EditUserRole';
import RegistrationLinkModal from '../../components/Modals/RegistrationLink';
import { API } from '../../config/api';
import { getData, deleteData, postData, updateData, formatEndpoint } from '../../utils/api';
import { addToastNotification } from '../../actions/notifications';
import { PAGE_HEADERS, ROLE, ROLE_LABEL } from '../../constants';
import UserIcon from '../../images/components/user.svg';

class Team extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            perPage: 10,
            currentPage: 1,
            table: {
                widths: [],
                headers: [],
                data: []
            },
            appliedSort: {
                col: 1,
                value: 'role_id',
                sort: 'asc',
                callback: (sort) => this.applySort(sort)
            },
            tableHeaders: [
                {name: 'User', width: 35, field: 'last_name'},
                {name: 'Role', width: 20, field: 'role_id'},
                {name: 'Status', width: 20, field: 'is_active'},
                {name: 'Last Active', width: 20, field: 'updated_at'},
                {name: '', width: 5}
            ],
            totalUsers: 0,
            searchValue: '',
            activeModal: null,
            activeUser: null
        };
    }

    componentDidMount() {
        this.setupRoute();
    }

    componentWillUnmount() {
        clearTimeout();
    }

    async setupRoute() {
        try {
            const params = this.prepareUsersParams();

            const [ usersResult ] = await Promise.all([
                getData(API.users.users, params)
            ]);
            
            const users = usersResult.documents;
            const totalUsers = usersResult.count;

            this.setState({
                totalUsers
            }, () => {
                this.prepareUsersTable(users, totalUsers);
            });
        } catch(err) {
            this.setState({
                loading: false
            });
        }
    }

    async updateUsersData() {
        try {
            const params = this.prepareUsersParams();
            const usersResult = await getData(API.users.users, params);
    
            this.prepareUsersTable(usersResult.documents, usersResult.count);
        } catch(err) {
            this.setState({
                loading: false
            });
        }
    }

    prepareUsersParams() {
        const { perPage, currentPage, searchValue, appliedSort } = this.state;

        const params = {
            page: currentPage,
            count: perPage,
            sort: (appliedSort && appliedSort.value) ? appliedSort.value: 'role_id',
            direction: (appliedSort && appliedSort.sort) ? appliedSort.sort : 'asc'
        };

        if (searchValue) {
            params['email'] = searchValue;
        }

        return params;
    }

    prepareUsersTable(users, totalUsers) {
        let { table, currentPage, perPage, tableHeaders, appliedSort } = this.state;
        const totalPages = Math.ceil(totalUsers / perPage);

        table.data = users.map((user) => this.formatTableRow(user));
        table.widths = tableHeaders.map((hd) => hd.width);
        table.headers = tableHeaders.map((hd) => hd.name);
        table.sort = appliedSort;

        table.pagination = totalPages ? {
            totalPages,
            currentPage,
            onChange: (page) => this.changeTablePage(page),
            allowFirstPage: true,
            allowLastPage: (totalUsers >= 10000) ? false : true,
            allowPageInput: (totalUsers >= 10000) ? false : true,
            position: (users && perPage === users.length) ? 'both' : 'bottom'
        } : null;

        this.setState({
            table,
            loading: false
        });
    }

    changeTablePage(page) {
        this.setState({
            currentPage: page
        }, () => {
            this.setupRoute();
        });
    }

    formatTableRow(user) {
        const { currentUser } = this.props;

        return [{
            id: 1,
            value: `${user.first_name} ${user.last_name}${ (user.id === currentUser.id) ? ' (You)' : '' }`,
            image: user.avatar || '/',
            sub: user.email
        }, {
            id: 2,
            value: user.role_id,
            component: <Label label={ ROLE_LABEL[user.role_id] } className='primary t-mr1' />
        }, {
            id: 3,
            value: (user.is_blocked) ? 'Blocked' : ((user.is_active) ? 'Active' : 'Awaiting Registration'),
            status: (user.is_blocked) ? 'error' : ((user.is_active) ? 'success' : 'warning')
        }, {
            id: 4,
            value: moment(user.updated_at).format('DD/MM/YYYY'),
            sub: moment(user.updated_at).fromNow()
        }, {
            id: 5,
            value: (user.id !== currentUser.id && user.role_id !== ROLE.SUPER_ADMIN) ? 'actions' : '',
            actions: (user.id !== currentUser.id && user.role_id !== ROLE.SUPER_ADMIN) ? this.getUserActions(user) : null
        }];
    }

    getUserActions(user) {
        let actions = [
            [{
                name: 'Edit User Role',
                icon: 'pencil',
                action: () => this.setState({
                    activeModal: 'EDIT_USER_ROLE',
                    activeUser: user
                }) 
            }], [{
                name: 'Delete User',
                icon: 'trash',
                action: () => this.setState({
                    activeModal: 'DELETE_USER',
                    activeUser: user
                })
            }]
        ];

        console.log("actions = ", actions)

        if ( !user.is_active && !user.is_blocked ) { 
            console.log("user = ", user);
            // awaiting registration. 
            actions[0].push({
                name: 'Get Registration Link',
                icon: 'user',
                action: () => this.setState({
                    activeModal: 'USER_REGISTRATION_LINK',
                    activeUser: user
                }) 
            })
        }

        return actions;
    }

    runTableSearch(searchValue) {
        this.setState({
            searchValue,
            currentPage: 1
        }, () => {
            this.setupRoute();
        });
    }

    closeModals() {
        this.setState({
            activeUser: null,
            activeModal: null
        });
    }
    
    applySort(sort) {
        let { table, appliedSort, tableHeaders } = this.state;

        if (sort && sort.hasOwnProperty('col') && tableHeaders && tableHeaders[sort.col] && tableHeaders[sort.col].field) {
            appliedSort = sort;
            appliedSort.value = tableHeaders[sort.col].field;
            table.sort = appliedSort;
    
            this.setState({
                table,
                appliedSort
            }, () => {
                this.updateUsersData();
            });
        }
    }

    async deleteUser(user) {
        const { addToastNotification } = this.props;

        this.closeModals();

        try {
            const userId = user.id;
            const endpoint = formatEndpoint(API.users.user, { userId });

            await deleteData(endpoint);

            addToastNotification({
                type: 'success',
                message: `${user.first_name} ${user.last_name} has successfully been removed from your organisation.`
            });

            setTimeout(() => {
                this.setupRoute();
            }, 1000);
        } catch(err) {            
            addToastNotification({
                type: 'error',
                message: 'Something went wrong while trying to delete this user. Please try again.'
            });
        }
    }

    async inviteUser(newUser) {
        const { addToastNotification } = this.props;

        this.closeModals();

        try {
            await postData(API.auth.invite_user, newUser);

            addToastNotification({
                type: 'success',
                message: `${newUser.first_name} ${newUser.last_name} has successfully been invited to your organisation. They will be sent an email to finish setting up their account.`
            });

            setTimeout(() => {
                this.setupRoute();
            }, 1000);
        } catch(err) {
            addToastNotification({
                type: 'error',
                message: 'Something went wrong while trying to invite this user. Please try again.'
            });
        }
    }

    async editUserRole(user, roleId, roleName) {
        const { addToastNotification } = this.props;

        this.closeModals();

        try {
            const userId = user.id;
            const endpoint = formatEndpoint(API.users.user, { userId });
            const payload = { role_id: roleId };

            await updateData(endpoint, payload);

            addToastNotification({
                type: 'success',
                message: `You have successfully updated ${user.first_name} ${user.last_name}'s role to ${ roleName }.`
            });

            setTimeout(() => {
                this.setupRoute();
            });
        } catch(err) {
            addToastNotification({
                type: 'error',
                message: `There was an issue while attempting to update the user's role. Please try again.`
            });
        }
    }

    render() {
        const {
            loading, totalUsers, table, searchValue, activeUser, activeModal
        } = this.state;

        return (
            <PageWrapper
                search
                headerBar
                sideMenu
            >
                <PageHeader
                    title={PAGE_HEADERS.TEAM.TITLE}
                    subtitle={PAGE_HEADERS.TEAM.SUBTITLE}
                    primaryAction={{
                        name: 'Invite New User',
                        className:'primary',
                        icon: {icon: 'plus', styling: 't-mr1'},
                        onClick: () => this.setState({activeModal: 'INVITE_NEW_USER'})
                    }}
                />

                {loading ? <Loader /> : (
                    <Table
                        title={ 'Team Members' }
                        titleIcon={ 'users' }
                        className={ 'mb0' }
                        headers={ table.headers }
                        widths={ table.widths }
                        data={ table.data }
                        pagination={ table.pagination }
                        searchCallback={ {action: (searchValue) => this.runTableSearch(searchValue), value: searchValue} }
                        searchOnChange={ true }
                        allowSearch={ true }
                        totalItems={ totalUsers }
                        fallbackImage={ UserIcon }
                        sortOptions={ table.sort }
                        type="table"
                    />
                )}

                {activeModal && activeModal === 'INVITE_NEW_USER' && (
                    <InviteNewUserModal
                        toggleModal={ () => this.closeModals() }
                        inviteUserAction={ (newUser) => this.inviteUser(newUser) }
                    />
                )}

                {activeModal && activeModal === 'EDIT_USER_ROLE' && (
                    <EditUserRoleModal
                        toggleModal={ () => this.closeModals() }
                        action={ (user, role) => this.editUserRole(user, role)}
                        user={ activeUser }
                    />
                )}

                {activeModal && activeModal === 'USER_REGISTRATION_LINK' && (
                    <RegistrationLinkModal
                        toggleModal={ () => this.closeModals() }
                        user={ activeUser }
                    />
                )}

                {activeModal && activeModal === 'DELETE_USER' && (
                    <ConfirmationModal
                        item={ activeUser }
                        header={ 'Delete User Confirmation' }
                        headerIcon={ 'trash' }
                        subheader={ 'Are you sure you want to delete this user from your organisation?' }
                        toggleModal={ () => this.closeModals() }
                        confirmationAction={ (item) => this.deleteUser(item) }
                        itemText={ `${ activeUser.first_name } ${ activeUser.last_name } currently has a role of ${ ROLE_LABEL[activeUser.role_id].text } within your organisation.` }
                        confirmationText={ 'Removing this user is irreversible and will remove all of their associated data from your organisation account. Are you sure you want to delete this user from your organisation?' }
                    />
                )}
            </PageWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToastNotification: (toastNotification) => dispatch(addToastNotification(toastNotification))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Team);