import React, { Component } from 'react';
import { Modal, Input } from '@filament-ai/filament-ui';


export default class RegistrationLinkModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: ''
        };
    }

    render() {
        const {
            toggleModal,
            user
        } = this.props;

        return (
            <Modal
                toggleModal={ toggleModal }>

                <div className='w-100 flex flex-ac flex-jc flex-col' style={ { height: '90vh' } }>
                    <header className='w-100'>
                        <h2 className='bold mt0'>User Registration</h2>
                        <p className='t-mt1 text-secondary'>The following link will allow the user to register for this platform. 
                        If the user has not been sent the automated email then please copy the link below and share it with them via other means. </p>
                    </header>

                    <main className='w-100 relative my2' style={ { maxHeight: '45vh', overflowY: 'scroll' } }>
                        <section>
                            <Input
                                className='w-100'
                                label="Registration Link"
                                type="text"
                                disabled
                                inputId="language"
                                value={ `${document.location.origin}/accept-invite/${user.id}/${user.team_token}` }>
                            </Input>
                        </section>
                    </main>

                    <section className='mt2 w-100'>
                        <div className='flex flex-ac flex-jsb w-100'>
                            <button
                                className={ 'primary inverted mr1 w-49' }
                                id={ 'cancelButton' }
                                onClick={ () => toggleModal() }>
                                Close
                            </button>
                        </div>
                    </section>
                </div>

            </Modal>
        )
    }
}



