import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserProfile } from '@filament-ai/filament-ui';

import { API } from '../../config/api';
import { getData, updateData } from '../../utils/api';
import { setCurrentUser  } from '../../actions/auth';
import { addToastNotification } from '../../actions/notifications';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import EditProfileModal from '../../components/Modals/EditProfile';

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            activeModal: null
        };
        
        this.saveProfileChanges = this.saveProfileChanges.bind(this);
    }

    returnUserObject() {
        const { currentUser } = this.props;

        return currentUser ? {
            ...currentUser,
            firstName: currentUser.first_name,
            lastName: currentUser.last_name,
            role: currentUser.roles.name,
            biography: currentUser.biography || 'No biography set',
        } : {};
    }

    getUserOptions() {
        return [{
            name: 'Edit Profile',
            icon: 'pencil',
            action: () => this.setState({activeModal: 'EDIT_USER_PROFILE'})
        }];
    }

    async saveProfileChanges(e, user) {
        const { addToastNotification, setCurrentUser } = this.props;

        e.preventDefault();

        this.setState({
            activeModal: null
        });

        try {
            const updateResp = await updateData(API.users.current_user, user);

            if (updateResp.id) {
                const userResult = await getData(API.users.current_user);

                setCurrentUser(userResult);

                addToastNotification({
                    type: 'success',
                    message: 'Your profile has successfully been updated.'
                });
            }
        } catch(err) {
            addToastNotification({
                type: 'error',
                message: err.message
            });
        }
    }

    render() {
        const {
            user, loading, activeModal
        } = this.state;

        return (
            <PageWrapper
                headerBar
                search
                sideMenu
            >
                { (!loading) && (
                    <main>
                        <UserProfile
                            user={this.returnUserObject(user)}
                            userOptions={this.getUserOptions()}
                            disableKeywords
                            readonly={false}
                        />

                        {activeModal && activeModal === 'EDIT_USER_PROFILE' && (
                            <EditProfileModal
                                toggleModal={() => this.setState({activeModal: null})}
                                user={this.returnUserObject(user)}
                                saveProfileChanges={this.saveProfileChanges}
                            />
                        )}
                    </main>
                ) }

            </PageWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setCurrentUser,
        addToastNotification
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
