export const PAGE_HEADERS = {
    TEAM: {
        TITLE: `Team Members`,
        SUBTITLE: `View and manage all current users, or invite new people to the platform.`
    },
    VIEWFILES: {
        TITLE: `View Files`,
        SUBTITLE: `Here is a list of the files that have been uploaded and translated. \n Open one to download the file, see all sentences and translations as well as any differences between Watsons translation and a translators.`
    },
    FILE: {
        TITLE: `File Content`,
        SUBTITLE: `Here you can see all of the different sentences, both original, translated and different, so you know which file you are looking at and about to download.`
    },
    TRAIN_MODEL: {
        TITLE: `Train AI Model`,
        SUBTITLE: `From this page you can request a new language model be generated. This will use all the training data for that language to date, and will replace any previous language model that may have been created.`
    },
    JOBS: {
        TITLE: `Translation Jobs`,
        SUBTITLE: `A translation job has many steps - from uploading the original English-English file, all the way through to uploading a moderated returned file. This page allows you to monitor all of the jobs and their status.`
    }
};

export const MODAL_TITLES = {
    'INVITE_NEW_USER': {
        'TITLE': 'Invite New User',
        'SUBTITLE': 'As an admin of your organisation, you can invite a new user and select their role and default campaign.'
    },
    'UPLOAD_FILE': {
        'TITLE': 'Upload a new File',
        'SUBTITLE': 'Here you can upload a new file to be tranlsated by Watson. \n Please ensure it is in \'.xliff\' format and that the file tag in your file contains a parameter called \'original\' and has a value.',
    },
    'HUMAN_FILE': {
        'TITLE': 'Upload a Translated File',
        'SUBTITLE': 'Here is where you upload a file you translated to compare with Watsons translation. \n Please ensure that in the file tag, the parameter called original has the same value of the file you downloaded originally. \n Also that the file is in \'.xliff\' format'
    },
    'TRAIN_MODEL': {
        'TITLE': 'Train a language model',
        'SUBTITLE': 'Select the language of the model you wish to train. Please enter the abbreviated version.\n i.e The English abbreviation is: en, Spanish is: es... \nHeres is a list of all languages and their abbreviated codes:',
        'LINK': 'http://www.mathguide.de/info/tools/languagecode.html'
    }
};

export const EXTERNAL_IMAGES = {
    SPLASH: `https://images.unsplash.com/photo-1515508866870-7aa2a950a492?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1534&q=80`
};