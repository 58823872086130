export const SET_TOAST_NOTIFICATIONS = 'SET_TOAST_NOTIFICATIONS';
export const ADD_TOAST_NOTIFICATION = 'ADD_TOAST_NOTIFICATION';

export function setToastNotifications(toastNotifications) {
	return {
		type: SET_TOAST_NOTIFICATIONS,
		toastNotifications: toastNotifications.map((notif) => {
            return {
                ...notif,
                createdAt: new Date()
            };
		})
	};
}

export function addToastNotification(toastNotification) {
	const newNotification = {
        ...toastNotification,
        createdAt: new Date()
    };

	return {
		type: ADD_TOAST_NOTIFICATION,
		toastNotification: newNotification
	};
}