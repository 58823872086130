import React, { Component } from 'react';
import { Modal, FileDropzone, Icon } from '@filament-ai/filament-ui';
import { MODAL_TITLES } from '../../constants';
import axios from 'axios';
import { API } from '../../config/api';


export default class HumanFileModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: '',
            fileName: '',
            valid: false
        };
    }

    validateFile(fileName) {
        this.setState({fileName: fileName});
        const fileBits = fileName.split(".");
        if(fileBits[fileBits.length - 1] === 'xliff'){
            return true;
        }else{
            return false;
        }
    }

    onFileDrop(files){
        this.setState({file: files[0]});
        const valid = this.validateFile(files[0].name);
        this.setState({valid: valid});        
    }

    async sendFile(){
        const { uploadHumanAction } = this.props;
        try {
            
            let formData = new FormData();
            formData.append('file', this.state.file);
            axios.post(API.translate.human_translation, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
            }});
            uploadHumanAction(true);
        } catch (error) {
            uploadHumanAction(false)
        }   
    }

    error(){
        const { uploadHumanAction } = this.props;
        uploadHumanAction(false);
    }

    render() {
        const {
            toggleModal
        } = this.props;

        const {
            fileName, file, valid
        } = this.state;

        return (
            <Modal
                toggleModal={ toggleModal }>

                <div className='w-100 flex flex-ac flex-jc flex-col' style={ { height: '90vh' } }>
                    <header className='w-100'>
                        <h2 className='bold mt0'>{ MODAL_TITLES.HUMAN_FILE.TITLE }</h2>
                        <p className='t-mt1 text-secondary'>{ MODAL_TITLES.HUMAN_FILE.SUBTITLE }</p>
                    </header>

                    <main className='w-100 relative my2' style={ { maxHeight: '45vh', overflowY: 'scroll' } }>
                        <section>
                            <FileDropzone
                                className={'w-100 mt2 height-100'}
                                onDrop={(files) => this.onFileDrop(files)}
                                files={[]}
                                acceptedTypes={'.xliff'}
                                text={valid === false ? 'Upload a file you have translated by either clicking here or dragging a valid XLIFF file into this area.' : 'File: ' + fileName}
                            />
                        </section>
                    </main>
                    <section className='mt2 w-100'>
                        <div className='flex flex-ac flex-jsb w-100'>
                            <button
                                className={ 'primary inverted mr1 w-49' }
                                id={ 'cancelButton' }
                                onClick={ () => toggleModal() }>
                                Cancel
                            </button>

                            <button
                                className={ 'primary mr1 w-49' }
                                disabled={ !this.state.valid}
                                onClick={ () => file != null ? this.sendFile(file): this.error()}
                                id={'uploadButton'}>
                                
                                <div>Upload<Icon icon='upload' className='ml1' /></div>
                            </button>
                        </div>
                    </section>
                </div>

            </Modal>
        )
    }
}



