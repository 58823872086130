import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Table } from '@filament-ai/filament-ui';

import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Loader from '../../components/Loader/Loader';

// import UploadFile from '../../components/Modals/UploadFile';
// import HumanFile from '../../components/Modals/HumanFile';
import { API } from '../../config/api';
import { getData, deleteData } from '../../utils/api';
import { addToastNotification } from '../../actions/notifications';
import { PAGE_HEADERS, ROUTES } from '../../constants';
import history from '../../config/history';

class Files extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            perPage: 25,
            currentPage: 1,
            table: {
                widths: [],
                headers: [],
                data: []
            },
            tableHeaders: [
                { name: 'File', width: 35, field: 'file' },
                { name: 'File ID', width: 35, field: 'fileid' },
                { name: 'Language', width: 15, filed: 'language' }
            ],
            searchValue: '',
            activeModal: null,
        };
    }

    componentDidMount() {
        this.setupRoute();
    }

    componentWillUnmount() {
        clearTimeout();
    }

    async setupRoute() {
        try {
            const params = this.prepareFileParams();

            const [filesResult] = await Promise.all([
                getData(API.translate.get_files, params)
            ]);

            const files = filesResult.documents;
            const totalFiles = filesResult.count;

            this.setState({
                totalFiles
            }, () => {
                this.prepareFileTable(files, totalFiles);
            });
        } catch (err) {
            this.setState({
                loading: false
            });
        }
    }

    prepareFileParams() {
        const { perPage, currentPage, searchValue, appliedSort } = this.state;

        const params = {
            page: currentPage,
            count: perPage,
            direction: (appliedSort && appliedSort.sort) ? appliedSort.sort : 'asc'
        };

        if (searchValue) {
            params['filename'] = searchValue;
        }

        return params;
    }

    prepareFileTable(files, totalFiles) {
        let { table, currentPage, perPage, tableHeaders, appliedSort } = this.state;
        const totalPages = Math.ceil(totalFiles / perPage);

        table.data = files.map((file) => this.formatTableRow(file));
        table.widths = tableHeaders.map((hd) => hd.width);
        table.headers = tableHeaders.map((hd) => hd.name);
        table.sort = appliedSort;

        table.pagination = totalPages ? {
            totalPages,
            currentPage,
            onChange: (page) => this.changeTablePage(page),
            allowFirstPage: true,
            allowLastPage: (totalFiles >= 10000) ? false : true,
            allowPageInput: (totalFiles >= 10000) ? false : true,
            position: (files && perPage === files.length) ? 'both' : 'bottom'
        } : null;

        this.setState({
            table,
            loading: false
        });
    }

    changeTablePage(page) {
        this.setState({
            currentPage: page
        }, () => {
            this.setupRoute();
        });
    }

    formatTableRow(file) {
        return [{
            id: 1,
            value: `${file.filename}`,
        },
        {
            id: 2,
            value: `${file.fileid}`,
        },
        {
            id: 3,
            value: `${file.language}`,
        }, {
            id: 4,
            value: `actions`,
            actions: this.getOption(file.fileid, file)
        }];
    }

    async deleteFile(file) { 
        await deleteData(API.translate.files + file.id);
        this.setupRoute();
    }

    getOption(file, fileObj) {
        return [
            [{
                name: 'Open File',
                icon: 'file',
                action: () => history.push(`${ROUTES.FILE}/${fileObj.id}`)
            },
            {
                name: 'Delete File',
                icon: 'trash',
                action: () => this.deleteFile(fileObj)
            }]
        ];
    }

    runTableSearch(searchValue) {
        this.setState({
            searchValue,
            currentPage: 1
        }, () => {
            this.setupRoute();
        });
    }

    closeModals() {
        this.setState({
            activeModal: null
        });
    }

    render() {
        const {
            loading, table
        } = this.state;

        return (
            <PageWrapper
                search
                headerBar
                sideMenu
            >
                <PageHeader
                    title={PAGE_HEADERS.VIEWFILES.TITLE}
                    subtitle={PAGE_HEADERS.VIEWFILES.SUBTITLE}
                />

                {loading ? <Loader /> : (
                    <Table
                        title={'Files'}
                        titleIcon={'file'}
                        className={'mb0'}
                        headers={table.headers}
                        widths={table.widths}
                        data={table.data}
                        pagination={table.pagination}
                        type="table"
                    />
                )}
            </PageWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToastNotification: (toastNotification) => dispatch(addToastNotification(toastNotification))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Files);