import axios from '../config/axios';

export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_AUTH_HEADERS = 'SET_AUTH_HEADERS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export function setLoginPending(isLoginPending) {
    return {
        type: SET_LOGIN_PENDING,
        isLoginPending
    };
}

export function setLoginSuccess(isLoginSuccess) {
    return {
        type: SET_LOGIN_SUCCESS,
        isLoginSuccess
    };
}

export function setLoginError(loginError) {
    return {
        type: SET_LOGIN_ERROR,
        loginError
    };
}

export function setAuthHeaders(userId, authToken) {
    axios.setToken(authToken);

    return {
        type: SET_AUTH_HEADERS,
        userId,
        authToken
    };
}

export function setCurrentUser(currentUser) {
    return {
        type: SET_CURRENT_USER,
        currentUser
    };
}

export function logout() {
    return (dispatch) => {
        dispatch(setLoginSuccess(false));
        dispatch(setAuthHeaders(null, null));
    };
}
