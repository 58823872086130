import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Button } from '@filament-ai/filament-ui';

import { login } from '../../utils/auth';
import { validateEmail } from '../../utils/validations';
import { ROUTES } from '../../constants';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSuccess: false,
            showError: false,
            message: '',
            email: '',
            password: '',
            loginLoading: false
        };

        this.submitLoginForm = this.submitLoginForm.bind(this);
    }

    componentDidMount() {
        const { isLoginSuccess, currentUser } = this.props;

        if (isLoginSuccess && currentUser) {
			this.checkRedirect();
		}
    }

	componentDidUpdate(prevProps) {
		const { isLoginSuccess, loginError, currentUser } = this.props;

		if (isLoginSuccess !== prevProps.isLoginSuccess || loginError !== prevProps.loginError) {
			if (isLoginSuccess && currentUser) {
				this.checkRedirect();
			} else if (loginError) {
				this.setState({
					showError: true,
					message: 'Your login credentials are incorrect. Please try again.'
				});
			}
		}
	}
    
    checkRedirect() {
		const { location } = this.props;


        if (location && location.state && location.state.nextPathname) {
            this.setState({
                redirect: location.state.nextPathname
            });
        } else {
            this.setState({
                redirect: ROUTES.LANDING
            });
        }
	}

    async submitLoginForm(event) {
		if (event) {
			event.preventDefault();
        }
        
        this.setState({
            loginLoading: true
        });

		const { email, password } = this.state;
		const { login } = this.props;

		const validEmail = validateEmail(email);

		if (validEmail) {
			await login(email, password);
		} else {
			this.setState({
				showError: true,
                message: 'Please enter a valid email address.',
                loginLoading: false
			});
		}
	}

    render() {
        const { email, password, showError, message, redirect, loginLoading } = this.state;

		if (redirect) {
			return <Redirect push to={{ pathname: redirect, state: null }} />
		}

		return (
			<div>
                <div className='flex flex-ac flex-jsb'>
                    <div className='page-height bg-white flex-centred w-40'>
                        <header className='w-65 w-85-tab'>
                            <h4 className='mt3'>Login</h4>
                            <p className='accent-dark small'>
                                Enter your email and password to login to the platform.
                            </p>

                            <form className='my2'>
                                <Input
                                    type='email'
                                    placeholder='Enter your email address...'
                                    inputId='emailAddress'
                                    label='Email'
                                    error={ showError }
                                    value={ email }
                                    errorMessage={ message }
                                    onChange={ (e) => this.setState({email: e.target.value, showError: false}) }
                                />

                                <Input
                                    type='password'
                                    placeholder='Enter your password...'
                                    inputId='password'
                                    label='Password'
                                    error={ showError }
                                    value={ password }
                                    onChange={ (e) => this.setState({password: e.target.value, showError: false}) }
                                />

                                <div className='flex flex-afs flex-jfs'>
                                    <p className='small accent-dark mt0 t-mr1'>Forgotten your password?</p>
                                    <Link
                                        className='small'
                                        to={ROUTES.RESET_PASSWORD}
                                        id={ 'resetPasswordLink' }
                                    >
                                        Reset it.
                                    </Link>
                                </div>

                                <Button
                                    className='primary w-100 mt2'
                                    onClick={ (e) => this.submitLoginForm(e) }
                                    id={ 'loginButton' }
                                    disabled={ !email.length|| !password.length || showError }
                                    name='Login'
                                    loading={loginLoading}
                                />
                            </form>
                        </header>
                    </div>

                    <div className='w-60 page-height full-page-image'
                        style={{backgroundSize : 'contain', backgroundImage: `url(https://www.orthoracle.com/content/themes/redwire-footatlas/assets/images/orth-oracle-home-logo.png)`}}>
                    </div>
                </div>
			</div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoginPending: state.auth.isLoginPending,
        isLoginSuccess: state.auth.isLoginSuccess,
        loginError: state.auth.loginError,
        authToken: state.auth.authToken,
        currentUser: state.auth.currentUser
    };
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		login
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
