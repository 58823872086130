import { SET_DARK_MODE, SET_SEARCH_VALUE, SET_SIDE_MENU } from '../actions/preferences';

const initialState = {
    darkMode: false,
    sideMenuOpen: true,
    searchValue: ''
};

function preferencesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DARK_MODE:
            return {
                ...state,
                darkMode: action.payload,
            };
        case SET_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload,
            };
        case SET_SIDE_MENU:
            return {
                ...state,
                sideMenuOpen: action.payload,
            };
        default:
            return state;
    }
}

export default preferencesReducer;