import moment from 'moment';

const passwordCaseRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
const passwordLengthRegex = /^([a-zA-Z0-9_-]){8,32}$/;
const passwordNumberRegex = /(?=.*[0-9])/;

 const INVALID_MESSAGES = {
    PASSWORD_ALL: 'Password must be between 8 and 32 characters long, contain at least one upper case character, one lower case character, and one number.',
    PASSWORD_LENGTH: 'Password must be between 8 and 32 characters long.',
    PASSWORD_CHARACTERS: 'Password must contain at least one upper case character, one lower case character, and one number.'
};

export function validatePassword(password) {
    const exists = password && password.length > 0;

    const includesBothCases = passwordCaseRegex.test(password);
    const includesNumber = passwordNumberRegex.test(password);
    const isCorrectLength = passwordLengthRegex.test(password);

    return exists && includesBothCases && includesNumber && isCorrectLength;
}

export function getInvalidPasswordMessage(password) {
    let message = '';

    const includesBothCases = passwordCaseRegex.test(password);
    const includesNumber = passwordNumberRegex.test(password);
    const isCorrectLength = passwordLengthRegex.test(password);

    if (!isCorrectLength && !includesNumber && !includesBothCases) {
        message = INVALID_MESSAGES.PASSWORD_ALL;
    } else if (!isCorrectLength) {
        message = INVALID_MESSAGES.PASSWORD_LENGTH;
    } else if (!includesNumber || !includesBothCases) {
        message = INVALID_MESSAGES.PASSWORD_CHARACTERS;
    }

    return message;
}

export function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return re.test(String(email).toLowerCase());
}

export function validatePhoneNumber(number) {
	const re = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;

	return re.test(String(number).toLowerCase());
}

export function validateDate(date) {
	return moment(date, 'DD/MM/YYYY', true).isValid();
}

export function validatePrice(price) {
	return !price || Number.isInteger(parseInt(price));
}
