import { combineReducers } from 'redux';

import authReducer from './auth';
import notificationsReducer from './notifications';
import preferencesReducer from './preferences';

const rootReducer = combineReducers({
    auth: authReducer,
    notifications: notificationsReducer,
    preferences: preferencesReducer
});

export default rootReducer;