import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';

import Loader from '../../components/Loader/Loader';
import { loginSSO } from '../../utils/auth';

class SSO extends Component {
    componentDidMount() {
        const { isLoginPending, location, loginSSO } = this.props;

        if (location.hash) {
            const params = qs.parse(location.hash);
            const idToken = params['#id_token'] || params['id_token'];
            const provider = params['#provider'] || params['provider'];

            if (idToken && !isLoginPending) {
                setTimeout(() => {
                    loginSSO({ Authorization: idToken, auth_provider: provider });
                }, 1000);
            }
        }
    }

    componentDidUpdate(prevProps) {
		const { isLoginSuccess, loginError, history } = this.props;

		if (isLoginSuccess !== prevProps.isLoginSuccess) {
			if (isLoginSuccess) {
				history.push('/landing');
			}
        }

        if (loginError !== prevProps.loginError) {
            if (loginError) {
				history.push('/login');
			}
        }
    }
    
    componentWillUnmount() {
        clearTimeout();
    }

    render() {
        return (
            <main className='flex flex-ac flex-jc flex-col' style={ {height: '100vh'} }>
                <Loader />
                <p className='lead text-secondary centred'>Authenticating with your Single Sign On provider. Please wait...</p>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoginPending: state.auth.isLoginPending,
        isLoginSuccess: state.auth.isLoginSuccess,
        loginError: state.auth.loginError
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginSSO: (idToken) => dispatch(loginSSO(idToken))
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SSO);
  