import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input, Icon, Button } from '@filament-ai/filament-ui';

import { API } from '../../config/api';
import { postData } from '../../utils/api';
import { validateEmail } from '../../utils/validations';
import { ROUTES } from '../../constants';

export default class SendResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            emailSent: false
        };
    }

    async submitForm(e) {
        if (e) {
            e.preventDefault();
        }

        const { email } = this.state;

        try {
            const payload = { email };
            const endpoint = API.auth.reset_password;

            await postData(endpoint, payload);

            this.setState({
                emailSent: true
            });
        } catch(err) {
            this.setState({
                emailSent: true
            });
        }
    }

    render() {
        const { email, emailSent } = this.state;

        return (
        <div className='page-height bg-white flex-centred w-100'>
            { (emailSent) ? (
                <header className='w-55 w-85-tab'>
                    <div className='mt3 flex flex-ac flex-jc flex-col' style={ {height: '100%'} }>
                        <h1 className='centred mb1 massive-icon'>
                            <Icon icon='check-circle' className='success' />
                        </h1>
                        <p className='lead text-secondary centred'>We have sent you an email with instructions on how to reset your password.</p>
                        <Link to={ROUTES.LOGIN} className='button primary w-30 mt2'>← Back to Login</Link>
                    </div>
                </header>
            ) : (
                <header className='w-55 w-85-tab'>
                    <Link to={ROUTES.LOGIN}>← Back to Login</Link>
                    <h4 className='mt3'>Reset Password</h4>
                    <p className='accent-dark small'>
                        Enter your email to receive an email with instructions on how to reset your password.
                    </p>

                    <form className='my2' onSubmit={(e) => this.submitForm(e)}>
                        <Input
                            type='email'
                            placeholder='Enter your email address...'
                            inputId='emailAddress'
                            label='Email'
                            error={ email.length > 0 && !validateEmail(email) }
                            errorMessage='Please make sure you enter a valid email address.'
                            value={ email }
                            onChange={ (e) => this.setState({email: e.target.value}) }
                        />

                        <Button
                            className='primary w-100 t-mt2'
                            disabled={ email.length === 0 || !validateEmail(email) }
                            onClick={ (e) => this.submitForm(e) }
                            name='Reset'
                        />
                    </form>
                </header>
            ) }
        </div>
        )
    }
}
