import React, { Component } from 'react';
import { Modal } from '@filament-ai/filament-ui';
import PropTypes from 'prop-types';

class ConfirmationModal extends Component {
    getModalActions() {
        const { toggleModal, item, confirmationAction } = this.props;

        return [{
            name: 'Cancel',
            id: 'cancelConfirmationButton',
            class: 'primary inverted',
            action: () => toggleModal()
        }, {
            name: 'Confirm',
            id: 'confirmationModalButton',
            class: 'primary',
            action: () => confirmationAction(item)
        }];
    }

    render() {
        const {
            toggleModal, confirmationText, header, headerIcon, subheader, itemText
        } = this.props;

        return (
            <Modal
                toggleModal={toggleModal}
                fitModal
                header={header}
                headerIcon={headerIcon}
                subheader={subheader}
                actions={this.getModalActions()}
            >
                <div className='w-100'>
                    <p className='bold'>{itemText}</p>
                    {confirmationText && <p>{confirmationText}</p>}
                </div>
            </Modal>
        )
    }
}

ConfirmationModal.defaultProps = {
    header: 'Confirmation',
    subheader: 'Are you sure?'
};

ConfirmationModal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    item: PropTypes.object,
    confirmationAction: PropTypes.func,
    confirmationText: PropTypes.string,
    header: PropTypes.string,
    headerIcon: PropTypes.string,
    subheader: PropTypes.string,
    itemText: PropTypes.string
};

export default ConfirmationModal;