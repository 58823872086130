const hostname = process.env.NODE_ENV;
export const API_ROOT = hostname === 'development' ? 'http://andyfeltham.me:4000/api' : '/api';

export const API = {
    auth: {
        login: `${API_ROOT}/auth/login`,
        login_sso: `${API_ROOT}/auth/sso`,
        invite_user: `${API_ROOT}/auth/invite`,
        accept_invite: `${API_ROOT}/auth/invite/{id}/{token}`,
        invite_register: `${API_ROOT}/auth/register`,
        reset_password: `${API_ROOT}/auth/reset-password`,
        submit_password: `${API_ROOT}/auth/reset-password/{id}/{token}`,
    },
    users: {
        current_user: `${API_ROOT}/user`,
        users: `${API_ROOT}/users`,
        user: `${API_ROOT}/users/{userId}`
    },
    translate: {
        get_single_file: `${API_ROOT}/translation/get-file-sentences`,
        get_files: `${API_ROOT}/translation/get-files`,
        translate_document: `${API_ROOT}/translation/translate-document`,
        train_model: `${API_ROOT}/translation/train-model`,
        human_translation: `${API_ROOT}/translation/human-translation`,
        send_file: `${API_ROOT}/translation/send-file`,
        files: `${API_ROOT}/translation/files/`,
        get_jobs : `${API_ROOT}/translations/jobs`,
        change_job : `${API_ROOT}/translations/jobs/`,
        models : `${API_ROOT}/watson/models`
    }
};