import axios from 'axios';

axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.setToken = (authToken) => {
    axios.defaults.headers.common['Authorization'] = `bearer ${authToken}`;
    axios.defaults.headers.get['Authorization'] = `bearer ${authToken}`;
}

export default axios;