// import { lazy } from 'react';

// const Login = lazy(() => import('../pages/Auth/Login'));
// const SendResetPassword = lazy(() => import('../pages/Auth/SendResetPassword'));
// const ResetPassword = lazy(() => import('../pages/Auth/ResetPassword'));
// const AcceptInvite = lazy(() => import('../pages/Auth/AcceptInvite'));
// const SSO = lazy(() => import('../pages/Auth/SSO'));
// const Profile = lazy(() => import('../pages/User/Profile'));
// const Team = lazy(() => import('../pages/User/Team'));
// const ViewFiles = lazy(() => import('../pages/Dashboard/View-Files'));
// const File = lazy(() => import('../pages/Dashboard/File'));
// const TrainModel = lazy(() => import('../pages/Dashboard/TrainModel'));
// const Jobs = lazy(() => import('../pages/Dashboard/Jobs'));

import { Login, SendResetPassword, ResetPassword, AcceptInvite, SSO, Profile, Team, ViewFiles, File, TrainModel, Jobs } from '../pages';

export default [{
    path: '/',
    exact: true,
    component: Login,
    private: false
}, {
    path: '/sso',
    exact: true,
    component: SSO,
    private: false
}, {
    path: '/accept-invite/:id/:token',
    exact: true,
    component: AcceptInvite,
    private: false
}, {
    path: '/reset-password',
    exact: true,
    component: SendResetPassword,
    private: false
}, {
    path: '/reset-password/:id/:token',
    exact: true,
    component: ResetPassword,
    private: false
}, {
    path: '/profile',
    exact: true,
    component: Profile,
    private: true
}, {
    path: '/team',
    exact: true,
    component: Team,
    private: true
}, {
    path: '/view-files',
    exact: true,
    component: ViewFiles,
    private: true
}, {
    path: '/file/:file_id',
    exact: true,
    component: File,
    private: true
}, {
    path: '/train',
    exact: true,
    component: TrainModel,
    private: true
}, {
    path: '/jobs',
    exact: true,
    component: Jobs,
    private: true
}];