import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { Router } from 'react-router-dom';

import history from './config/history';
import axios from './config/axios';
import { logout } from './utils/auth';
import configureStore from './config/store';
import { loadState, saveState } from './config/localStorage';
import Routes from './pages/Routes';

import '@filament-ai/filament-ui/dist/index.css';
import './index.css';

const persistedState = loadState();

const store = configureStore(
    persistedState
);

store.subscribe(() => {
    saveState(store.getState());
});

if (store.getState().auth && store.getState().auth.authToken) {
    axios.setToken(store.getState().auth.authToken);
}

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <Routes />
                </Router>
            </Provider>
        );
    }
}


const UNAUTHORIZED = 401;
const { dispatch } = store;

axios.interceptors.response.use(
    response => response,
    error => {
        if ( error ) { 
            const { status } = error.response;

            if (status === UNAUTHORIZED) {
                dispatch(logout());
            }
        }

        return Promise.reject(error);
    }
);

const mapStateToProps = (state) => {
    return {
        isLoginSuccess: state.auth.isLoginSuccess
    };
}

export default connect(mapStateToProps)(App);