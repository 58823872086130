export const ROLE = {
    ADMIN: 1,
    STANDARD: 2,
    READ_ONLY: 3,
    SUPER_ADMIN: 4
};

export const ROLE_LABEL = {
    1: {
        text: 'Admin',
        colour: '#25b47e',
        icon: 'user-crown'
    },
    2: {
        text: 'Standard',
        colour: '#5352ed'
    },
    3: {
        text: 'Read Only',
        colour: '#5352ed'
    },
    4: {
        text: 'Super Admin',
        colour: '#25b47e'
    }
};