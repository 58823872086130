import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Table } from '@filament-ai/filament-ui';
import axios from 'axios';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Loader from '../../components/Loader/Loader';

import { API } from '../../config/api';
import { getData } from '../../utils/api';
import { addToastNotification } from '../../actions/notifications';
import { PAGE_HEADERS } from '../../constants';
import FileIcon from '../../images/components/user.svg';

class Files extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileid: '',
            loading: true,
            table: {
                widths: [],
                headers: [],
                data: []
            },
            tableHeaders: [
                {name: 'English', width: 35, field: 'eng'},
                {name: 'Translated', width: 35, field: 'tra'},
                {name: 'Differences within translation', width: 30, field: 'dif'}
            ],
        };
    }

    componentDidMount() {
        this.setupRoute();
    }

    componentWillUnmount() {
        clearTimeout();
    }

    async setupRoute() {
        try {
            this.state.fileid = this.props.match.params.file_id;
            const [ filesResult ] = await Promise.all([
                getData(API.translate.get_single_file, {file: this.state.fileid})
            ]);

            const files = filesResult.documents;
            const totalFiles = filesResult.count;
            this.state.fileid = files[0]['fileid'];
            
            console.log("files[0]", files[0]);

            this.setState({
                totalFiles
            }, () => {
                this.prepareFileTable(files, totalFiles);
            });
        } catch(err) {
            this.setState({
                loading: false
            });
        }
    }

    prepareFileTable(sentences) {
        let { table, tableHeaders } = this.state;
        
        
        table.data = sentences.map((sentence) => this.formatTableRow(sentence));
        table.widths = tableHeaders.map((hd) => hd.width);
        table.headers = tableHeaders.map((hd) => hd.name);

        this.setState({
            table,
            loading: false
        });
    }

    formatTableRow(sentence) {
        return [{
                id: 1,
                value: `${sentence.original}`,
            },
            {
                id: 2,
                value: `${sentence.translated}`,
            },
            {
                id: 3,
                value: sentence.difference == null ? '' : `${sentence.difference}`
            }];
    }

    async downloadFile() {
        let { fileid } = this.state;
        const [ file ] = await Promise.all([
            axios({
                method: 'post',
                url: API.translate.send_file,
                headers: {}, 
                data: {
                  original: fileid,
                }})
        ]);
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(file));
        element.setAttribute('download', 'Translation-'+fileid+'.xliff');
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
    }

    render() {
        const {
            loading, table
        } = this.state;

        return (
            <PageWrapper
                search
                headerBar
                sideMenu
            >
                <PageHeader
                    title={PAGE_HEADERS.FILE.TITLE}
                    subtitle={PAGE_HEADERS.FILE.SUBTITLE}
                    primaryAction={{
                        name: 'Download EN-ES File',
                        className:'primary',
                        icon: {icon: 'download', styling: 't-mr1'},
                        onClick: () => this.downloadFile()
                    }}
                />
            
                {loading ? <Loader /> : (
                    <Table
                        title={ `File Content` }
                        titleIcon={ 'pen' }
                        className={ 'mb0' }
                        headers={ table.headers }
                        widths={ table.widths }
                        data={ table.data }
                        allowSearch={ false }
                        fallbackImage={ FileIcon }
                        type="table"
                    />
                )}
                </PageWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToastNotification: (toastNotification) => dispatch(addToastNotification(toastNotification))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Files);