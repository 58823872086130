export const ROUTES = {
    LOGIN: '/',
    RESET_PASSWORD: '/reset-password',
    LANDING: '/jobs',
    PROFILE: '/profile',
    TEAM: '/team',
    VIEW_FILES: '/view-files',
    FILE: '/file',
    JOBS: '/jobs',
    TRAIN: '/train'
};