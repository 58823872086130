import React, { Component } from 'react';
import { Modal, Input, Icon } from '@filament-ai/filament-ui';
import { MODAL_TITLES } from '../../constants';
import { API } from '../../config/api';
import { postData } from '../../utils/api';


export default class TrainModelModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: ''
        };
    }

    validateInput(language) {

        if(language.length === 2){
            return true;
        }else{
            return false;
        }
    }

    async sendReq(language){
        const { trainModelAction } = this.props;
        try {
            postData(API.translate.train_model, {language: language});

            trainModelAction(true);
        } catch (error) {
            trainModelAction(false);
        }
        
    }

    error(){
        const { trainModelAction } = this.props;

        trainModelAction(false);
    }

    render() {
        const {
            toggleModal
        } = this.props;

        const {
            language
        } = this.state;

        return (
            <Modal
                toggleModal={ toggleModal }>

                <div className='w-100 flex flex-ac flex-jc flex-col' style={ { height: '90vh' } }>
                    <header className='w-100'>
                        <h2 className='bold mt0'>{ MODAL_TITLES.TRAIN_MODEL.TITLE }</h2>
                        <p className='t-mt1 text-secondary'>{ MODAL_TITLES.TRAIN_MODEL.SUBTITLE }</p>
                        <a href={MODAL_TITLES.TRAIN_MODEL.LINK} target='blank'>{MODAL_TITLES.TRAIN_MODEL.LINK}</a>
                    </header>

                    <main className='w-100 relative my2' style={ { maxHeight: '45vh', overflowY: 'scroll' } }>
                        <section>
                            <p className='text-secondary'>Input your language here:</p>
                                <Input
                                    className='w-49'
                                    label="Language"
                                    type="text"
                                    placeholder="Language"
                                    inputId="language"
                                    success={ !!this.validateInput(language) }
                                    value={ language }
                                    onChange={ (e) => this.setState({ language: e.target.value }) }>
                                </Input>
        
                        </section>
                    </main>

                    <section className='mt2 w-100'>
                        <div className='flex flex-ac flex-jsb w-100'>
                            <button
                                className={ 'primary inverted mr1 w-49' }
                                id={ 'cancelButton' }
                                onClick={ () => toggleModal() }>
                                Cancel
                            </button>

                            <button
                                className={ 'primary mr1 w-49' }
                                disabled={ !this.validateInput(language)}
                                onClick={ () => language != null ? this.sendReq(language): this.error()}
                                id={'trainButton'}>
                                
                                <div>Train<Icon icon='brain' className='ml1' /></div>
                            </button>
                        </div>
                    </section>
                </div>

            </Modal>
        )
    }
}



