import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, ToastNotification, Button } from '@filament-ai/filament-ui';

import { API } from '../../config/api';
import { setToastNotifications, addToastNotification } from '../../actions/notifications';
import { login } from '../../utils/auth';
import { formatEndpoint, postData, getData } from '../../utils/api';
import { validatePassword, getInvalidPasswordMessage } from '../../utils/validations';
import { API_STATUS_CODES, ROUTES } from '../../constants';


class AcceptInvite extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: '',
            token: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            passwordConfirm: '',
            redirect: null
        };
    }

    async componentDidMount() {
        const { match } = this.props;

        await this.getInvitedUserDetails(match.params.id, match.params.token);    
    }
    
    componentDidUpdate(prevProps) {
		const { isLoginSuccess, loginError, currentUser, addToastNotification } = this.props;

		if (isLoginSuccess !== prevProps.isLoginSuccess || loginError !== prevProps.loginError) {
			if (isLoginSuccess && currentUser) {
				this.checkRedirect();
			} else if (loginError) {
                addToastNotification({
                    type: 'error',
                    message: 'Your login credentials are incorrect. Please try again.'
                });
			}
		}
    }
    
    checkRedirect() {
		const { location } = this.props;

        if (location && location.state && location.state.nextPathname) {
            this.setState({
                redirect: location.state.nextPathname
            });
        } else {
            this.setState({
                redirect: ROUTES.LANDING
            });
        }
	}

    async getInvitedUserDetails(id, token) {
        const { addToastNotification } = this.props;

        this.setState({
            id,
            token
        });

        try {
            const endpoint = formatEndpoint(API.auth.accept_invite, {id, token});
            const userResult = await getData(endpoint);

            this.setState({
                first_name: userResult.first_name,
                last_name: userResult.last_name,
                email: userResult.email
            });
        } catch(err) {
            addToastNotification({
                type: 'error',
                message: err.message || 'Your invite token has expired. Please ask your system admin to send you another one.'
            });
        }
    }

    async registerAccount(event) {
        event.preventDefault();

        const { first_name, last_name, email, password, id, token } = this.state;
        const { login, addToastNotification } = this.props;

        const payload = {
            first_name,
            last_name,
            email,
            password
        };

        try {
            const endpoint = formatEndpoint(API.auth.acceptInvite, { id, token });
            const resp = await postData(endpoint, payload);

            if (resp.status === API_STATUS_CODES.OK) {
                login(payload.email, payload.password);
            } else {
                throw new Error();
            }
        } catch(err) {
            this.setState({
                password: '',
                passwordConfirm: ''
            });

            addToastNotification({
                type: 'error',
                message: err.message || 'Your invite token has expired. Please ask your system admin to send you another one.'
            });
        }
    }

    clearNotification(index) {
        const { toastNotifications, setToastNotifications } = this.props;

        toastNotifications.splice(index, 1);

        setToastNotifications(toastNotifications);
    }

    render() {
        const { toastNotifications } = this.props;
        const { redirect, password, passwordConfirm, first_name, last_name, email} = this.state;
        
        if (redirect) {
            return <Redirect push to={ redirect } />
        }
        
        return (
            <div className='flex flex-ac flex-jsb'>
                <div className='page-height bg-white flex-centred w-100'>
                    <div className='w-55 w-85-tab'>
                        <h4>Hey, { first_name + ' ' + last_name }</h4>
                        <p className='accent-dark small'>
                            You have been invited to the join the team. Please choose a password to register your account with the email address <strong>{ email }</strong>.
                        </p>

                        <form className='my2' onSubmit={(e) => this.registerAccount(e)}>
                            <Input type='password'
                                placeholder='Enter your password...'
                                inputId='password'
                                label='Password'
                                error={ password.length > 0 && !validatePassword(password) }
                                errorMessage={ (password.length > 0) ? getInvalidPasswordMessage(password) : null }
                                value={ password }
                                onChange={ (e) => this.setState({password: e.target.value}) }>
                            </Input>

                            <Input
                                type='password'
                                placeholder='Confirm your password...'
                                inputId='passwordConfirm'
                                label='Confirm Password'
                                error={ password.length > 0 && passwordConfirm.length > 0 && passwordConfirm !== password }
                                errorMessage='Please make sure both password fields match.'
                                value={ passwordConfirm }
                                onChange={ (e) => this.setState({passwordConfirm: e.target.value}) }>
                            </Input>

                            <Button
                                className='primary w-100 t-mt2 mb2'
                                disabled={ password.length === 0 || password.length < 8 || passwordConfirm.length === 0 || password !== passwordConfirm }
                                onClick={ (e) => this.registerAccount(e) }
                                type='submit'
                                name='Register Account'
                            />

                            <Link to={ROUTES.LOGIN}>← Back to Login</Link>
                        </form>
                    </div>
                </div>

                { !!(toastNotifications && toastNotifications.length) && toastNotifications.map((toast, index) => (
                    <ToastNotification
                        key={ index }
                        stacked={ index + 1 }
                        type={ toast.type }
                        message={ toast.message }
                        onClick={ () => this.clearNotification(index) }
                    />
                )) }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoginPending: state.auth.isLoginPending,
        isLoginSuccess: state.auth.isLoginSuccess,
        loginError: state.auth.loginError,
        currentUser: state.auth.currentUser,
        toastNotifications: state.notifications.toastNotifications
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        login,
        addToastNotification,
        setToastNotifications
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvite);