import React, { Component, Suspense } from 'react';
import {
    Redirect,
    Route,
    Switch,
    withRouter
} from 'react-router-dom';
import { connect } from 'react-redux';

import routes from '../config/router';
import Loader from '../components/Loader/Loader';
import { ROUTES } from '../constants';

class Routes extends Component {
    returnRoutes() {
        return routes.map((route) => (
            this.checkRoutePermissions(route) ? (
                <Route
                    key={route.path}
                    {...route}
                />
            ) : <Redirect key={route.path} from='*' to={ROUTES.LOGIN} />
        ));
    }

    checkRoutePermissions(route) {
        const { isLoginSuccess, currentUser } = this.props;

        const routeAccess = !route.hide && ((route.private && isLoginSuccess && (!route.roles || (route.roles && currentUser && route.roles.includes(currentUser.role_id)))) || !route.private);

        return routeAccess;
    }

    render() {
        return (
            <Suspense fallback={<Loader />}>
                <Switch>
                    {this.returnRoutes()}
                    <Redirect from='*' to={ROUTES.LOGIN} />
                </Switch>
            </Suspense>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        isLoginSuccess: state.auth.isLoginSuccess,
        currentUser: state.auth.currentUser
    };
}

export default withRouter(connect(mapStateToProps, null)(Routes));