import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@filament-ai/filament-ui';

import LoaderRing from '../../images/components/loader-ring.gif';

class LoaderComponent extends Component {
    render() {
        const { size } = this.props;

        return (
            <Loader
                type={LoaderRing}
                size={size}
            />
        );
    }
}

LoaderComponent.defaultProps = {
    size: 'small'
};

LoaderComponent.propTypes = {
    size: PropTypes.oneOf(['small', 'x-small'])
};

export default LoaderComponent;