export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_SIDE_MENU = 'SET_SIDE_MENU';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';

export function setDarkMode(state) {
    return {
        type: SET_DARK_MODE,
        payload: state,
    };
}

export function setSideMenu(state) {
    return {
        type: SET_SIDE_MENU,
        payload: state,
    };
}

export function setSearchValue(state) {
    return {
        type: SET_SEARCH_VALUE,
        payload: state
    };
}