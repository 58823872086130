import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { PageHeader, Table, Icon } from '@filament-ai/filament-ui';

import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Loader from '../../components/Loader/Loader';

import UploadFile from '../../components/Modals/UploadFile';
import HumanFile from '../../components/Modals/HumanFile';
import { API } from '../../config/api';
import { getData } from '../../utils/api';
import { addToastNotification } from '../../actions/notifications';
import { PAGE_HEADERS, ROUTES } from '../../constants';
import history from '../../config/history';

class Jobs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            perPage: 25,
            currentPage: 1,
            table: {
                widths: [],
                headers: [],
                data: []
            },
            tableHeaders: [
                { name: 'File', width: 35, field: 'file' },
                { name: 'Translated', width: 10, field: 'gcp_translated_file_path' },
                { name: 'Moderated', width: 10, field: 'gcp_moderated_file_path' },
                { name: 'Status', width: 15, field: 'status' },
                { name: 'Updated', width: 20, field: 'last_update' },
                { name: '', width: 10 }
            ],
            searchValue: '',
            activeModal: null,
        };

        this.newJob = this.newJob.bind(this);
        this.setupRoute = this.setupRoute.bind(this);
    }

    componentDidMount() {
        this.setupRoute();
    }

    componentWillUnmount() {
        clearTimeout();
    }

    refresh () { 
        this.setState( { 
            loading: true
        }, () => { 
            this.setupRoute();
        })
    }

    async setupRoute() {
        try {
            const params = this.prepareFileParams();

            const [filesResult] = await Promise.all([
                getData(API.translate.get_jobs, params)
            ]);

            const files = filesResult.documents;
            const totalFiles = filesResult.count;

            for ( var i = 0; i < files.length; i ++ ) { 
                if ( files[i].status === "translating" || files[i].status === "initial" ) {
                    // check again in a second..
                    setTimeout(() => {
                        this.setupRoute();
                    }, 5000);
                    break;
                }
            }

            this.setState({
                totalFiles
            }, () => {
                this.prepareFileTable(files, totalFiles);
            });
        } catch (err) {
            this.setState({
                loading: false
            });
        }
    }

    prepareFileParams() {
        const { perPage, currentPage, searchValue, appliedSort } = this.state;

        const params = {
            page: currentPage,
            pageSize: perPage,
            direction: (appliedSort && appliedSort.sort) ? appliedSort.sort : 'asc'
        };

        if (searchValue) {
            params['filename'] = searchValue;
        }

        return params;
    }

    prepareFileTable(files, totalFiles) {
        let { table, currentPage, perPage, tableHeaders, appliedSort } = this.state;
        const totalPages = Math.ceil(totalFiles / perPage);

        table.data = files.map((file) => this.formatTableRow(file));
        table.widths = tableHeaders.map((hd) => hd.width);
        table.headers = tableHeaders.map((hd) => hd.name);
        table.sort = appliedSort;

        table.pagination = totalPages ? {
            totalPages,
            currentPage,
            onChange: (page) => this.changeTablePage(page),
            allowFirstPage: true,
            allowLastPage: (totalFiles >= 10000) ? false : true,
            allowPageInput: (totalFiles >= 10000) ? false : true,
            position: (files && perPage === files.length) ? 'both' : 'bottom'
        } : null;

        this.setState({
            table,
            loading: false
        });
    }

    changeTablePage(page) {
        this.setState({
            currentPage: page
        }, () => {
            this.setupRoute();
        });
    }

    formatTableRow(file) {
        return [{
            id: 1,
            value: `${file.original_file.filename}`,
        },
        {
            id: 2,
            value: `${file.gcp_translated_file_path ? 'yes' : ''}`,
            component : ( file.gcp_translated_file_path ? <Icon icon={[ 'fas', 'check-circle' ] }></Icon> : null )
        },
        {
            id: 3,
            value: `${file.gcp_moderated_file_path ? 'yes' : ''}`,
            component : ( file.gcp_moderated_file_path ? <Icon icon={[ 'fas', 'check-circle' ] }></Icon> : null )
        },
        {
            id: 4,
            value: `${file.status}`,
            component : ( file.status === 'translating' ? <React.Fragment><Icon className='fa-spin' icon={[ 'fas', 'spinner' ] }></Icon><p style={{ display: 'inline' }}>&nbsp;{file.status}</p></React.Fragment> : null )
        },
        {
            id: 5,
            value: `${moment(file.updated_at).format('MMM Do \'YY, h:mm:ss a')}`,
        }, {
            id: 6,
            value: `actions`,
            actions: this.getOption(file.fileid, file)
        }];
    }

    async downloadFile( fileObj ) {
        let fileid = fileObj.original_file.id;
        const [ file ] = await Promise.all([
            axios({
                method: 'post',
                url: API.translate.send_file,
                headers: {}, 
                data: {
                  original: fileid,
                }})
        ]);
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(file));
        element.setAttribute('download', 'Translated-'+fileObj.original_file.filename);
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
    }

    async cancelTranslation( fileObj ) {
        let fileid = fileObj.original_file.id;
        await axios({
            method: 'put',
            url: `${API.translate.change_job}/${fileid}`,
            headers: {}, 
            data: {
                status: 'cancelled'
            }});
        this.refresh();
    }

    async submitForReview ( fileObj ) { 
        let fileid = fileObj.original_file.id;
        await axios({
            method: 'put',
            url: `${API.translate.change_job}/${fileid}`,
            headers: {}, 
            data: {
                status: 'in_review'
            }});
        this.refresh();
    }

    getOption(file, fileObj) {
        let actions = [];
        if ( fileObj.gcp_original_file_path !== null ) {
            actions.push ( {
                name: 'Open File',
                icon: 'file',
                action: () => history.push(`${ROUTES.FILE}/${fileObj.original_file.id}`)
            });
        } else { 
            actions.push ( {
                name: 'Unable to Open File',
                icon: 'file'
            });
        }

        if ( fileObj.gcp_translated_file_path !== null ) { 
            actions.push ( {
                name: 'Download Automated Translation',
                icon: 'download',
                action: () => this.downloadFile(fileObj)
            });
        }

        if ( fileObj.status !== "cancelled" && fileObj.gcp_translated_file_path === null ) {
            actions.push ( {
                name: 'Cancel Translation',
                icon: 'ban',
                action: () => this.cancelTranslation(fileObj)
            });
        }

        if ( fileObj.status === "translated" && fileObj.gcp_moderated_file_path === null ) { 
            actions.push ( {
                name: 'Submit for review',
                icon: 'search',
                action: () => this.submitForReview(fileObj)
            });
        }

        if ( ( fileObj.status === "in_review" && fileObj.gcp_moderated_file_path === null ) || fileObj.status === "reviewed" ) { 
            actions.push ( {
                name: 'Upload Moderated Translation',
                icon: 'upload',
                action: () => this.setState({ activeModal: 'HUMAN_FILE' })
            });
        }

        return [
            actions
        ];
    }

    runTableSearch(searchValue) {
        this.setState({
            searchValue,
            currentPage: 1
        }, () => {
            this.setupRoute();
        });
    }

    closeModals() {
        this.setState({
            activeModal: null
        });
    }

    async uploadFile(uploaded) {
        const { addToastNotification } = this.props;

        this.closeModals();
        if (uploaded === true) {
            addToastNotification({
                type: 'success',
                message: `Congratulations, you have successfuly uploaded a file.`
            });

            setTimeout(() => {
                this.refresh();
            }, 1000);
        } else {
            addToastNotification({
                type: 'error',
                message: 'Something went wrong while uploading. Please try again.'
            });
        }
    }

    newJob () { 
        this.setState({ activeModal: 'UPLOAD_FILE' });
    }

    render() {
        const {
            loading, table, activeModal
        } = this.state;

        return (
            <PageWrapper
                search
                headerBar
                sideMenu
            >
                <PageHeader
                    title={PAGE_HEADERS.JOBS.TITLE}
                    subtitle={PAGE_HEADERS.JOBS.SUBTITLE}
                    primaryAction={{
                        name: 'New Translation Job',
                        className:'primary',
                        icon: {icon: 'language', styling: 't-mr1'},
                        action: () => this.newJob()
                    }}
                />

                {loading ? <Loader /> : (
                    <Table
                        title={'Translation Jobs'}
                        titleIcon={'clipboard-list'}
                        className={'mb0'}
                        headers={table.headers}
                        widths={table.widths}
                        data={table.data}
                        pagination={table.pagination}
                        type="table"
                        action={[ {
                            name: 'Refresh',
                            className:'primary',
                            icon: 'sync',
                            action: () => this.refresh()
                        } ] }
                    />
                )}

                {activeModal && activeModal === 'UPLOAD_FILE' && (
                    <UploadFile
                        toggleModal={() => this.closeModals()}
                        uploadFileAction={(uploaded) => this.uploadFile(uploaded)}
                    />
                )}
                {activeModal && activeModal === 'HUMAN_FILE' && (
                    <HumanFile
                        toggleModal={() => this.closeModals()}
                        uploadHumanAction={(uploaded) => this.uploadFile(uploaded)}
                    />
                )}
            </PageWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToastNotification: (toastNotification) => dispatch(addToastNotification(toastNotification))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);