import React, { Component } from 'react';
import { Modal, StepBar, Input, Icon, OptionCard } from '@filament-ai/filament-ui';

import { validateEmail } from '../../utils/validations';
import { MODAL_TITLES, ROLE_LABEL } from '../../constants';

const STEPS = Object.freeze({
    "USER_DETAILS": 0,
	"ROLE_SELECT": 1
});

export default class InviteNewUserModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: STEPS.USER_DETAILS,
            steps: [
                { complete: false, heading: 'User Details' },
                { complete: false, heading: 'Account Role' }
            ],
            firstName: '',
            lastName: '',
            email: '',
            teamId: null,
            orgRole: null,
            orgRoleOptions: []
        };
    }

    componentDidMount() {
        this.setupModal();
    }

    setupModal() {
        const orgRoleOptions = Object.keys(ROLE_LABEL).map((key) => {
            return {
                id: key,
                displayName: ROLE_LABEL[key].text,
                ...ROLE_LABEL[key]
            }
        });

        this.setState({
            orgRoleOptions
        });
    }

    clickStep(step, index) {
        let { steps, currentStep } = this.state;

        if (step.complete) {
            currentStep = index;

            steps.forEach((st, ind) => st.complete = (ind >= index) ? false : true);

            this.setState({
                steps,
                currentStep
            });
        }
    }

    backStep(curStep) {
        let { steps, currentStep } = this.state;

        steps[curStep - 1].complete = false;
        currentStep -= 1;

        this.setState({
            steps,
            currentStep
        });
    }

    validateStep(currentStep) {
        const { firstName, lastName, email, orgRole } = this.state;

        if (currentStep === STEPS.USER_DETAILS) {
            return firstName && firstName.length > 0 && lastName && lastName.length > 0 && validateEmail(email);
        } else if (currentStep === STEPS.ROLE_SELECT) {
            return orgRole;
        } else {
            return true;
        }
    }

    nextStep(curStep) {
        let { steps, currentStep } = this.state;

        steps[curStep].complete = true;
        currentStep += 1;

        this.setState({
            steps,
            currentStep
        });
    }

    inviteUser() {
        const { inviteUserAction } = this.props;

        const {
            firstName, lastName, email, orgRole
        } = this.state;

        const payload = {
            first_name: firstName,
            last_name: lastName,
            email,
            role_id: orgRole
        };

        inviteUserAction(payload);
    }

    render() {
        const {
            toggleModal
        } = this.props;

        const {
            steps, currentStep, firstName, lastName, email, orgRoleOptions, orgRole
        } = this.state;

        return (
            <Modal
                toggleModal={ toggleModal }>

                <div className='w-100 flex flex-ac flex-jc flex-col' style={ { height: '90vh' } }>
                    <header className='w-100'>
                        <h2 className='bold mt0'>{ MODAL_TITLES.INVITE_NEW_USER.TITLE }</h2>
                        <p className='t-mt1 text-secondary'>{ MODAL_TITLES.INVITE_NEW_USER.SUBTITLE }</p>

                        <StepBar
                            steps={ steps }
                            current={ currentStep }
                            clickSteps={ (step, index) => this.clickStep(step, index) }
                        />
                    </header>

                    <main className='w-100 relative my2' style={ { maxHeight: '45vh', overflowY: 'scroll' } }>
                        { (currentStep === STEPS.USER_DETAILS) ? (
                            <section>
                                <p className='text-secondary'>Enter the name and email address of the user you want to invite.</p>
                                <div className='flex flex-ac flex-jsb'>
                                    <Input
                                        className='w-49'
                                        label="First Name"
                                        type="text"
                                        placeholder="John"
                                        inputId="firstName"
                                        success={ !!firstName }
                                        value={ firstName }
                                        onChange={ (e) => this.setState({ firstName: e.target.value }) }>
                                    </Input>
                                    <Input
                                        className='w-49'
                                        label="Surname"
                                        type="text"
                                        placeholder="Blogs"
                                        inputId="surname"
                                        success={ !!lastName }
                                        value={ lastName }
                                        onChange={ (e) => this.setState({ lastName: e.target.value }) }>
                                    </Input>
                                </div>
                                <Input
                                    label="Email"
                                    type="text"
                                    placeholder="john.blogs@company.com"
                                    inputId="email"
                                    success={ !!email && validateEmail(email) }
                                    error={ !!email && !validateEmail(email) }
                                    errorMessage={ (!!email && !validateEmail(email)) ? "Please enter a valid email address" : null }
                                    value={ email }
                                    onChange={ (e) => this.setState({ email: e.target.value }) }>
                                </Input>
                            </section>
                        ) : null }

                        { (currentStep === STEPS.ROLE_SELECT) ? (
                            <div>
                                <p className='text-secondary'>Select a role for the new user. This will affect what permissions the user has on your organisation account.</p>

                                { (orgRoleOptions.length > 0) ? (
                                    <div className='grid grid-w-48 px1 py1'>
                                        {orgRoleOptions.map((rl, index) => (
                                            <OptionCard
                                                key={ index }
                                                className="w-100"
                                                onClick={ () => this.setState({ orgRole: rl.id }) }
                                                icon={ rl.icon || 'user' }
                                                header={ rl.displayName }
                                                subheader={ rl.description }
                                                features={ rl.permissionsFeatures }
                                                selected={ orgRole === rl.id }
                                                showFeatures={ false }
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <p className='my1'>No roles available.</p>
                                ) }
                            </div>
                        ) : null}
                    </main>

                    <section className='mt2 w-100'>
                        <div className='flex flex-ac flex-jsb w-100'>
                            <button
                                className={ 'primary inverted mr1 ' + ((currentStep !== STEPS.USER_DETAILS) ? 'w-32' : 'w-49') }
                                id={ 'cancelButton' }
                                onClick={ () => toggleModal() }>
                                Cancel
                            </button>

                            {(currentStep > STEPS.USER_DETAILS) ? (
                                <button
                                    className={ 'primary inverted mr1 w-32' }
                                    onClick={ () => this.backStep(currentStep) }>
                                    <Icon icon='arrow-left' className='mr1' />
                                    Back
                                </button>
                            ) : null}

                            <button
                                className={ `primary ${ (currentStep !== STEPS.USER_DETAILS) ? 'w-32' : 'w-49' }` }
                                disabled={ !this.validateStep(currentStep)}
                                onClick={ () => currentStep === 1 ? this.inviteUser() : this.nextStep(currentStep) }
                                id={'nextStepButton'}>
                                { (currentStep === STEPS.ROLE_SELECT) ? 'Invite User'
                                    : <div>Next <Icon icon='arrow-right' className='ml1' /></div>
                                }
                            </button>
                        </div>
                    </section>
                </div>

            </Modal>
        )
    }
}