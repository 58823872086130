import React, { Component } from 'react';
import { Modal, SelectDropdown } from '@filament-ai/filament-ui';

import { ROLE_LABEL } from '../../constants';

export default class EditUserRoleModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRole: props.user.role_id,
            roleOptions: []
        };
    }

    componentDidMount() {
        const roleOptions = Object.keys(ROLE_LABEL).map((key) => {
            return {
                id: key,
                displayName: ROLE_LABEL[key].text,
                name: ROLE_LABEL[key].text,
                ...ROLE_LABEL[key]
            }
        });

        this.setState({
            roleOptions
        });
    }

    render() {
        const {
            toggleModal, user, action, header, headerIcon, subheader
        } = this.props;

        const { selectedRole, roleOptions } = this.state;

        return (
            <Modal
                toggleModal={ toggleModal }
                fitModal
                header={ header || 'Edit User Role' }
                headerIcon={ headerIcon || 'pencil' }
                subheader={ subheader || 'Editing a user role will affect how this user can use and interact with this application.' }
                actions={ [
                    {name: 'Cancel', class: 'primary inverted', action: () => toggleModal() },
                    {name: 'Confirm', id: 'confirmButton', disabled: !selectedRole || selectedRole.id === user.role_id, class: 'primary', action: () => action(user, selectedRole, ROLE_LABEL[selectedRole].text)}
                ] }>

                <div className='w-100'>
                    <p>{ `${user.first_name} ${user.last_name}'s ` }Current Role: <strong>{ ROLE_LABEL[user.role_id].text }</strong></p>

                    { (roleOptions && roleOptions.length > 0) ? (
                        <SelectDropdown
                            className="w-100"
                            selectText={ "Select a Role" }
                            options={ roleOptions }
                            inputId={'changeRoleDropdown'}
                            default={ ROLE_LABEL[selectedRole].text }
                            callback={ (selectedRole) => this.setState({selectedRole: selectedRole.id}) }
                        />
                    ) : (
                        <p className='my1 text-secondary'>No roles available.</p>
                    ) }

                    { selectedRole && (
                        <p className='my1'><strong>{ ROLE_LABEL[selectedRole].text } Permissions:</strong> { ROLE_LABEL[selectedRole].description }</p>
                    ) }
                        
                </div>

            </Modal>
        )
    }
}