export const API_STATUS_CODES = Object.freeze({
    OK: 200,
    FORBIDDEN: 401,
    NOT_FOUND: 404,
    ERROR: 500
});

export const API_ERROR_CODES = [
    API_STATUS_CODES.FORBIDDEN,
    API_STATUS_CODES.NOT_FOUND,
    API_STATUS_CODES.ERROR
];