import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { PageHeader, Table } from '@filament-ai/filament-ui';

import Loader from '../../components/Loader/Loader';

import TrainModel from '../../components/Modals/TrainModel';

import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { addToastNotification } from '../../actions/notifications';
import { PAGE_HEADERS } from '../../constants';
import { API } from '../../config/api';
import { getData } from '../../utils/api';

class TrainNewModel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            activeModal: null,
            perPage: 25,
            currentPage: 1,
            table: {
                widths: [],
                headers: [],
                data: []
            },
            tableHeaders: [
                { name: 'Model Name', width: 20, field: 'file' },
                { name: 'Previous Model Name', width: 20, field: 'file' },
                { name: 'Language', width: 20, field: 'fileid' },
                { name: 'Status', width: 20, filed: 'language' },
                { name: 'Updated', width: 20, field: 'last_update' }
            ]
        };
    }

    componentDidMount() {
        this.setupRoute();
    }

    componentWillUnmount() {
        clearTimeout();
    }

    closeModals() {
        this.setState({
            activeModal: null
        });
    }

    refresh () { 
        this.setState( { 
            loading: true
        }, () => { 
            this.setupRoute();
        })
    }

    async trainModel(success) {
        const { addToastNotification } = this.props;

        this.closeModals();
        if (success === true) {
            addToastNotification({
                type: 'success',
                message: `Your language model is training, this can take a while.\nThe previous model will be used to translate until this one is available`
            });

            setTimeout(() => {
                this.refresh();
            }, 1000);
        } else {
            addToastNotification({
                type: 'error',
                message: 'Something went wrong with the training request.'
            });
        }
    }

    async setupRoute() {
        try {
            const params = this.prepareFileParams();

            const [filesResult] = await Promise.all([
                getData(API.translate.models, params)
            ]);

            const files = filesResult.documents;
            const totalFiles = filesResult.count;

            this.setState({
                totalFiles
            }, () => {
                this.prepareFileTable(files, totalFiles);
            });
        } catch (err) {
            console.log("err = ", err);
            this.setState({
                loading: false
            });
        }
    }

    prepareFileParams() {
        const { perPage, currentPage, searchValue, appliedSort } = this.state;

        const params = {
            page: currentPage,
            pageSize: perPage,
            direction: (appliedSort && appliedSort.sort) ? appliedSort.sort : 'asc'
        };

        if (searchValue) {
            params['filename'] = searchValue;
        }

        return params;
    }

    prepareFileTable(files, totalFiles) {
        let { table, currentPage, perPage, tableHeaders, appliedSort } = this.state;
        const totalPages = Math.ceil(totalFiles / perPage);

        table.data = files.map((file) => this.formatTableRow(file));
        table.widths = tableHeaders.map((hd) => hd.width);
        table.headers = tableHeaders.map((hd) => hd.name);
        table.sort = appliedSort;

        table.pagination = totalPages ? {
            totalPages,
            currentPage,
            onChange: (page) => this.changeTablePage(page),
            allowFirstPage: true,
            allowLastPage: (totalFiles >= 10000) ? false : true,
            allowPageInput: (totalFiles >= 10000) ? false : true,
            position: (files && perPage === files.length) ? 'both' : 'bottom'
        } : null;

        this.setState({
            table,
            loading: false
        });
    }

    changeTablePage(page) {
        this.setState({
            currentPage: page
        }, () => {
            this.refresh();
        });
    }

    formatTableRow(file) {
        return [{
            id: 1,
            value: `${file.modelname}`
        },
        {
            id: 2,
            value: `${file.previousmodel}`
        },
        {
            id: 3,
            value: `${file.language}`
        }, {
            id: 4,
            value: `${file.status}`
        }, {
            id: 5,
            value: `${moment(file.updated_at).format('MMM Do \'YY, h:mm:ss a')}`,
        }];
    }

    render() {
        const {
            loading, table, activeModal
        } = this.state;

        return (
            <PageWrapper
                search
                headerBar
                sideMenu
            >
                <PageHeader
                    title={PAGE_HEADERS.TRAIN_MODEL.TITLE}
                    subtitle={PAGE_HEADERS.TRAIN_MODEL.SUBTITLE}
                    primaryAction={{
                        name: 'Train Model',
                        className:'primary',
                        icon: {icon: 'brain', styling: 't-mr1'},
                        action: () => this.setState({ activeModal: 'TRAIN_MODEL' })
                    }}
                />

                {loading ? <Loader /> : (
                    <Table
                        title={'Models'}
                        titleIcon={'brain'}
                        className={'mb0'}
                        headers={table.headers}
                        widths={table.widths}
                        data={table.data}
                        pagination={table.pagination}
                        type="table"
                        action={[ {
                            name: 'Refresh',
                            className:'primary',
                            icon: 'sync',
                            action: () => this.refresh()
                        } ] }
                    />
                )}

                {activeModal && activeModal === 'TRAIN_MODEL' && (
                    <TrainModel
                        toggleModal={() => this.closeModals()}
                        trainModelAction={(success) => this.trainModel(success)}
                    />
                )}
            </PageWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToastNotification: (toastNotification) => dispatch(addToastNotification(toastNotification))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrainNewModel);