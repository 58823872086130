import {
    SET_LOGIN_PENDING,
    SET_LOGIN_SUCCESS,
    SET_LOGIN_ERROR,
    SET_AUTH_HEADERS,
    SET_CURRENT_USER
} from '../actions/auth';

const initial_state = {
    isLoginSuccess: false,
    isLoginPending: false,
    loginError: null,
    userId: null,
    authToken: null,
    currentUser: null
};

function auth(state = initial_state, action) {
    switch (action.type) {
        case SET_LOGIN_PENDING:
            return {
                ...state,
                isLoginPending: action.isLoginPending
            };
        case SET_LOGIN_SUCCESS:
            return {
                ...state,
                isLoginSuccess: action.isLoginSuccess
            };
        case SET_LOGIN_ERROR:
            return {
                ...state,
                loginError: action.loginError
            };
        case SET_AUTH_HEADERS:
            return {
                ...state,
                userId: action.userId,
                authToken: action.authToken
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.currentUser
            };
        default:
            return state;
    }
}

export default auth;