import { API } from '../config/api';
import { postData, getData } from '../utils/api';
import { setLoginError, setLoginPending, setLoginSuccess, setAuthHeaders, setCurrentUser } from '../actions/auth';

export async function callLoginApi(email, password) {
    return await postData(API.auth.login, {
        email,
        password
    });
}

export async function callLoginSSOApi(headers) {
    return await getData(API.auth.login_sso, { headers });
}

export async function getCurrentUser() {
    return await getData(API.users.current_user);
}

export function hasPermission(user, permission) { 
    return user.roles.permissions.find(p => p.name === permission)
}

export function login(email, password) {
    return async (dispatch) => {
        dispatch(setLoginPending(true));
        dispatch(setLoginSuccess(false));
        dispatch(setLoginError(null));

        const loginResponse = await callLoginApi(email, password);
        dispatch(setLoginPending(false));
        dispatch(setAuthHeaders(loginResponse.id, loginResponse.token));

        if (loginResponse && loginResponse.id) {
            const userResponse = await getCurrentUser();
            dispatch(setCurrentUser(userResponse));
            dispatch(setLoginSuccess(true));
        } else {
            dispatch(setLoginPending(false));
            dispatch(setLoginSuccess(false));
            dispatch(setLoginError(new Error('Error logging in')));
        }
    };
}

export function loginSSO(headers) {
    return async (dispatch) => {
        dispatch(setLoginPending(true));
        dispatch(setLoginSuccess(false));
        dispatch(setLoginError(null));

        const loginResponse = await callLoginSSOApi(headers);
        dispatch(setLoginPending(false));
        dispatch(setAuthHeaders(loginResponse.id, loginResponse.token));

        if (loginResponse && loginResponse.id) {
            const userResponse = await getCurrentUser();
            dispatch(setCurrentUser(userResponse));
            dispatch(setLoginSuccess(true));
        } else {
            dispatch(setLoginPending(false));
            dispatch(setLoginSuccess(false));
            dispatch(setLoginError(new Error('Error logging in')));
        }
    };
}

export function logout() {
    return (dispatch) => {
        dispatch(setLoginSuccess(false));
        dispatch(setAuthHeaders(null, null));
        dispatch(setCurrentUser(null));
    };
}
