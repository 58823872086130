import React, { Component } from 'react';
import { Modal, Input } from '@filament-ai/filament-ui';

export default class EditProfileModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changed: false,
            user: props.user
        };
    }

    setInputState(field, value) {
        let { user } = this.state;
    
        user[field] = value;
    
        this.setState({
          user,
          changed: true
        });
    }

    checkFields() {
        const { user } = this.state;

        let disabled = false;

        if (user.firstName.length === 0 || user.lastName.length === 0) {
            disabled = true;
        }

        return disabled;
    }

    saveProfileChangesCall(e, user) {
        const { saveProfileChanges } = this.props;

        const updatedUser = {
            first_name: user.firstName,
            last_name: user.lastName,
            location: user.location,
            biography: user.biography
        };

        saveProfileChanges(e, updatedUser);
    }

    render() {
        const { toggleModal } = this.props;
        const { changed, user } = this.state;

        return (
            <Modal
                toggleModal={ toggleModal }>

                <div className='w-100 flex flex-ac flex-jc flex-col' style={{ height: '90vh' }}>
                    <header className='w-100'>
                        <h2 className='bold mt0'>Edit Profile</h2>
                        <p className='mt0 text-secondary'>Here you can edit your profile details (e.g. first and last name, location, and biography).</p>
                    </header>

                    <form className='w-100 my3'>
                        <div className='flex flex-ac flex-jsb'>
                            <Input
                                className='w-49'
                                type="text"
                                placeholder="First name"
                                label="First Name"
                                inputId="firstName"
                                value={ user.firstName }
                                onChange={ (e) => this.setInputState('firstName', e.target.value) } >
                            </Input>
                            <Input
                                className='w-49'
                                type="text"
                                placeholder="Last Name"
                                label="Last Name"
                                inputId="Last Name"
                                value={ user.lastName }
                                onChange={ (e) => this.setInputState('lastName', e.target.value) } >
                            </Input>
                        </div>

                        <Input
                            type="text"
                            placeholder="Location"
                            label="Location"
                            inputId="location"
                            value={ user.location }
                            onChange={ (e) => this.setInputState('location', e.target.value) } >
                        </Input>

                        <Input
                            type="text"
                            placeholder="Biography"
                            label="Biography"
                            inputId="biography"
                            error={ user.biography.length > 500 }
                            errorMessage={ 'Please make sure your biography consists of 500 characters or fewer.' }
                            value={ user.biography }
                            onChange={ (e) => this.setInputState('biography', e.target.value) } >
                        </Input>
                    </form>

                    <footer className='mt1 w-100'>
                        <div className='flex flex-ac flex-jsb w-100'>
                            <button className='primary inverted mr1 w-49' id={'cancelButton'} onClick={ () => toggleModal(false) }>Close</button>
                            <button className='primary mr1 w-49' id={'saveChangesButton'} disabled={ !changed || this.checkFields() } onClick={ (e) => this.saveProfileChangesCall(e, user) }>Save Changes</button>
                        </div>
                    </footer>
                </div>

            </Modal>
        )
    }
}