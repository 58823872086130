import {
    SET_TOAST_NOTIFICATIONS,
    ADD_TOAST_NOTIFICATION
} from '../actions/notifications';

const initial_state = {
    toastNotifications: []
};

export default function notifications(state = initial_state, action) {
	switch (action.type) {
		case ADD_TOAST_NOTIFICATION:
			let toastNotifications = state.toastNotifications || [];
			
			if (!toastNotifications.includes(action.toastNotification)) {
                toastNotifications.push(action.toastNotification);
			}
			
			if (toastNotifications.length >= 6) {
				toastNotifications.splice(0, 1);
			}

            return {
                ...state,
                toastNotifications
			};
		case SET_TOAST_NOTIFICATIONS:
			return {
				...state,
				toastNotifications: action.toastNotifications
			}
		default:
			return state;
	}
}